import { isDesktop } from 'bv';

export const calculateScrollPosition = ({ section }, navRef, fixedNav) => {
  const header = document.querySelector('.site-header');
  const headerHeight = header?.clientHeight || 0;
  const navHeight = navRef.current.clientHeight;
  const sectionHeight = section.current.offsetTop;
  const adjustment = fixedNav && !isDesktop() ? 0 : 60; // Needed for fixed nav changing dom sizing
  const navIsLeft = isDesktop() && window.innerWidth >= 1024;

  const newPosition = sectionHeight - (!navIsLeft && navHeight) - headerHeight - adjustment;

  if (!header?.classList.contains('site-header--scrolling') && isDesktop()) return newPosition + 30; // Account for header changing size
  return newPosition;
};
