import { v as bvVar } from 'bv';
import { loggedIn } from 'bv-helpers/session';
import routingSettings from '../config/routing_config';
import { getAccountData } from './account';

const departmentByName = (departmentName) => window.zE('webWidget:get', 'chat:department', departmentName);

const setDepartmentOrContactUsForm = (departments) => {
  const onlineDepartment = departments.find((department) => (
    departmentByName(department)?.status === 'online'
  ));

  if (onlineDepartment) {
    window.zESettings.webWidget.chat.departments = {
      select: departmentByName(onlineDepartment).id,
    };
    window.zE('webWidget', 'updateSettings', window.zESettings.webWidget);
  } else {
    window.zE('webWidget', 'updateSettings', routingSettings('contactForm'));
  }
};

export const loggedOutRouting = () => {
  const { routing } = bvVar('zendeskConfig');

  setDepartmentOrContactUsForm(routing.logged_out);
};

export const loggedInRouting = (groupLevel) => {
  const { routing } = bvVar('zendeskConfig');

  setDepartmentOrContactUsForm(routing.cs_levels[String(groupLevel)]);
};

export const handleDepartmentStatusChange = () => {
  // Delay is needed to allow chat server to update. Otherwise isChatting is always true
  setTimeout(() => {
    if (!window.zE('webWidget:get', 'chat:isChatting')) {
      if (loggedIn()) {
        getAccountData().then((data) => loggedInRouting(data.customer_service_level));
      } else {
        loggedOutRouting();
      }
    }
  }, 250);
};
