const {
  VCSTATE,
  JSBundles,
  CSSBundles,
  TranslationsBundles,
  BVVars,
} = window;

const initCasino = () => import(/* webpackChunkName: "casino_global" */ '../../app/casino/global/redux/index.js');
const initSportsbook = () => import(/* webpackChunkName: "sportsbook" */ '../../app/sportsbook/init.js');
const sportsbookOverview = () => import(/* webpackChunkName: "sportsbook_overview" */ '../../app/sportsbook/overview/index.js');
const acceptAllLineChangesToggle = () => import(/* webpackChunkName: "accept_all_line_changes_toggle" */ '../../app/accept_all_line_changes_toggle/index.jsx');
const acceptAllPriceChangesToggle = () => import(/* webpackChunkName: "accept_all_price_changes_toggle" */ '../../app/accept_all_price_changes_toggle/index.jsx');
const layoutApps = () => import(/* webpackChunkName: "layout_apps" */ '../../app/layout_apps/index.js');
const luckyDip = () => import(/* webpackChunkName: "lucky_dip" */ '../../app/sportsbook_components/lucky_dip/index.js');
const inPlayOverview = () => import(/* webpackChunkName: "in_play_overview" */ '../../app/sportsbook_components/in_play_overview_components/index.js');
const scoreboard = () => import(/* webpackChunkName: "scoreboard" */ '../../app/sportsbook_components/scoreboard/index.jsx');
const contactUs = () => import(/* webpackChunkName: "contact_us" */ '../../app/contact_us/index.js');
const horseRacing = () => import(/* webpackChunkName: "horse_racing" */ '../../app/horse_racing_racecard/index.js');

const casinoSectionComponents = {
  app: () => import(/* webpackChunkName: "casino_sections" */ '../../app/casino/section_components/index.js'),
  dependencies: [
    initCasino,
    CSSBundles.casinoV2,
  ],
};

const genericQuestionnaire = {
  app: () => import(/* webpackChunkName: "questionnaire" */ '../../app/questionnaire/index.jsx'),
  dependencies: [
    CSSBundles.questionnaire,
  ],
};

const sportSections = {
  app: () => import(/* webpackChunkName: "sport_sections" */ '../../app/sport_sections/index.js'),
  dependencies: [
    initSportsbook,
    sportsbookOverview,
    CSSBundles.inPlayOverview,
    CSSBundles.priceItUp,
    CSSBundles.errorPage,
    CSSBundles.betBoost,
    CSSBundles.eventLevel,
    CSSBundles.scoreboard,
  ],
};

const selfExclude = {
  app: () => import(/* webpackChunkName: "self_exclude" */ '../../app/self_exclude/index.jsx'),
  dependencies: [
    CSSBundles.selfExclude,
    CSSBundles.accountForms,
  ],
  softDependencies: [
    TranslationsBundles.selfExclusion,
  ],
};

const stakeLimitsCommon = {
  app: () => import(/* webpackChunkName: "stake_limits" */ '../../app/stake_limits/index.jsx'),
  dependencies: [
    CSSBundles.stakeLimits,
    CSSBundles.accountForms,
  ],
};

const occupationAndSalary = {
  app: () => import(/* webpackChunkName: "occupation_and_salary" */ '../../app/occupation_and_salary/index.js'),
  dependencies: [
    CSSBundles.occupationAndSalary,
    CSSBundles.saferGamblingPortal,
  ],
  softDependencies: [
    TranslationsBundles.occupationAndSalary,
  ],
};

const luckyDipDependency = (subModule) => ({
  app: luckyDip,
  dependencies: [
    JSBundles.sportsbook,
    CSSBundles.luckyDip,
  ],
  subModule,
});

export default {
  homeComponents: {
    app: () => import(/* webpackChunkName: "home_components" */ '../../app/home_components/index.js'),
  },
  banners: {
    app: () => import(/* webpackChunkName: "banners" */ '../../globals/components/banners/index.js'),
    dependencies: [
      CSSBundles.banners,
    ],
  },
  featured: {
    app: () => import(/* webpackChunkName: "featured" */ '../../app/sportsbook_components/featured/index.js'),
    dependencies: [
      initSportsbook,
      CSSBundles.eventLevel, // TODO: convert event level to generic sportsbook styles
      CSSBundles.scoreboard,
    ],
  },
  upcomingRaces: {
    app: () => import(/* webpackChunkName: "upcoming_races" */ '../../app/sportsbook_components/upcoming_races/index.js'),
    dependencies: [
      initSportsbook,
    ],
  },
  trendingBets: {
    app: () => import(/* webpackChunkName: "trading_bets" */ '../../app/sportsbook_components/trending_bets/index.js'),
    dependencies: [
      initSportsbook,
    ],
  },
  priceItUp: {
    app: () => import(/* webpackChunkName: "price_it_up" */ '../../app/sportsbook_components/price_it_up/index.js'),
    dependencies: [
      CSSBundles.priceItUp,
      CSSBundles.betBuilder,
      initSportsbook,
    ],
  },
  gamesList: {
    ...casinoSectionComponents,
    subModule: 'GamesList',
  },
  videoGamesList: {
    ...casinoSectionComponents,
    subModule: 'VideoGamesList',
  },
  liveCasinoGamesList: {
    ...casinoSectionComponents,
    subModule: 'LiveCasinoGamesList',
  },
  currentCategoryGamesComponent: {
    ...casinoSectionComponents,
    subModule: 'CurrentCategoryGamesComponent',
  },
  widget: {
    ...casinoSectionComponents,
    subModule: 'Widget',
  },
  seo: {
    app: () => import(/* webpackChunkName: "seo" */ '../../app/seo/index.js'),
  },
  userInboxIcon: {
    app: () => import(/* webpackChunkName: "user_inbox_icon" */ '../../app/user_inbox/icon/index.jsx'),
  },
  settings: {
    app: () => import(/* webpackChunkName: "settings" */ '../../app/settings/index.js'),
    dependencies: [
      CSSBundles.settings,
    ],
    softDependencies: [
      TranslationsBundles.acceptAllPriceChangesToggle,
      TranslationsBundles.acceptAllLineChangesToggle,
    ],
  },
  userInbox: {
    app: () => import(/* webpackChunkName: "user_inbox" */ '../../app/user_inbox/modal/index.jsx'),
    dependencies: [
      CSSBundles.userInbox,
    ],
  },
  userMessages: {
    app: () => import(/* webpackChunkName: "user_messages" */ '../../app/user_messages/index.js'),
  },
  accountOverview: {
    app: () => import(/* webpackChunkName: "account_overview" */ '../../app/account_overview/index.js'),
    dependencies: [
      CSSBundles.accountOverview,
    ],
    softDependencies: [
      // Account overview uses portal translations
      // From now on this should change, even if some keys are duplicated in different scopes
      TranslationsBundles.saferGamblingPortal,
    ],
  },
  referAFriend: {
    app: () => import(/* webpackChunkName: "refer_a_friend" */ '../../app/refer_a_friend/index.jsx'),
    dependencies: [
      CSSBundles.referAFriend,
      CSSBundles.offers,
    ],
  },
  referAFriendAsia: {
    app: () => import(/* webpackChunkName: "refer_a_friend_asia" */ '../../app/refer_a_friend_asia/index.jsx'),
    dependencies: [
      CSSBundles.referAFriendAsia,
      CSSBundles.offers,
    ],
  },
  sowCollection: {
    app: () => import(/* webpackChunkName: "sow_collection" */ '../../app/sow_collection/index.jsx'),
    dependencies: [
      CSSBundles.sowCollection,
    ],
    softDependencies: [
      TranslationsBundles.kycStrategies,
      TranslationsBundles.sowCollection,
    ],
  },
  cashierActions: {
    app: () => import(/* webpackChunkName: "cashier_actions" */ '../../app/cashier_actions/index.js'),
    dependencies: [
      CSSBundles.cashierActions,
      CSSBundles.liveChat,
      CSSBundles.accountForms,
    ],
    softDependencies: [
      TranslationsBundles.stakeLimits,
      TranslationsBundles.payments,
      TranslationsBundles.quickDeposit,
    ],
  },
  contactUs: {
    app: contactUs,
    dependencies: [
      CSSBundles.contactUs,
    ],
  },
  requestCallback: {
    app: () => import(/* webpackChunkName: "request_callback" */ '../../app/request_callback/index.js'),
    dependencies: [
      CSSBundles.requestCallback,
      CSSBundles.registration,
      CSSBundles.accountForms,
      CSSBundles.intTelInput,
    ],
  },
  accountHistory: {
    app: () => import(/* webpackChunkName: "account_history" */ '../../app/account_history/index.js'),
    dependencies: [
      CSSBundles.offers,
      CSSBundles.accountHistory,
    ],
  },
  passwordChange: {
    app: () => import(/* webpackChunkName: "password_change" */ '../../app/password_change/index.jsx'),
    dependencies: [
      CSSBundles.accountForms,
      CSSBundles.passwordChange,
    ],
  },
  passwordReset: {
    app: () => import(/* webpackChunkName: "password_reset" */ '../../app/password_reset/index.js'),
    dependencies: [
      CSSBundles.accountForms,
      CSSBundles.sendVerifyCode,
      CSSBundles.liveChat,
    ],
  },
  activeSessions: {
    app: () => import(/* webpackChunkName: "active_sessions" */ '../../app/active_sessions/index.jsx'),
    dependencies: [
      CSSBundles.accountSecurity,
    ],
    softDependencies: [
      TranslationsBundles.security,
    ],
  },
  security: {
    app: () => import(/* webpackChunkName: "security" */ '../../app/security/index.jsx'),
    dependencies: [
      JSBundles.passwordChange,
      JSBundles.activeSessions,
      CSSBundles.accountSecurity,
      CSSBundles.active_sessions,
    ],
    softDependencies: [
      TranslationsBundles.security,
    ],
  },
  registration: {
    app: () => import(/* webpackChunkName: "registration" */ '../../app/registration/index.js'),
    dependencies: [
      CSSBundles.occupationAndSalary,
      CSSBundles.registration,
      CSSBundles.accountForms,
      CSSBundles.intTelInput,
      CSSBundles.liveChat,
      CSSBundles.signInAndUpTabs,
    ],
    softDependencies: [
      TranslationsBundles.occupationAndSalary,
    ],
    features: {
      occupationAndSalary,
    },
  },
  registrationV2: {
    app: () => import(/* webpackChunkName: "registration_v2" */ '../../app/registration_v2/index.js'),
    dependencies: [
      CSSBundles.occupationAndSalary,
      CSSBundles.registration,
      CSSBundles.accountForms,
      CSSBundles.intTelInput,
      CSSBundles.liveChat,
    ],
    softDependencies: [
      TranslationsBundles.occupationAndSalary,
      TranslationsBundles.registration,
    ],
    features: {
      occupationAndSalary,
    },
  },
  verificationZone: {
    app: () => import(/* webpackChunkName: "verification_zone" */ '../../app/verification_zone/index.jsx'),
    dependencies: [
      CSSBundles.verificationZone,
    ],
    softDependencies: [
      TranslationsBundles.kycStrategies,
      TranslationsBundles.saferGamblingPortal,
      TranslationsBundles.sowCollection,
    ],
  },
  personalDetails: {
    app: () => import(/* webpackChunkName: "personal_details" */ '../../app/personal_details/index.jsx'),
    dependencies: [
      CSSBundles.personalDetails,
      CSSBundles.sendVerifyCode,
      CSSBundles.accountForms,
      CSSBundles.occupationAndSalary,
      CSSBundles.intTelInput,
    ],
    softDependencies: [
      TranslationsBundles.saferGamblingPortal,
      TranslationsBundles.occupationAndSalary,
    ],
    features: {
      occupationAndSalary,
    },
  },
  questionnaire: genericQuestionnaire,
  saferGamblingQuestionnaire: {
    app: () => import(/* webpackChunkName: "safer_gambling_questionnaire" */ '../../app/safer_gambling_questionnaire/index.js'),
    softDependencies: [
      TranslationsBundles.saferGamblingQuestionnaire,
    ],
  },
  saferGamblingPortal: {
    app: () => import(/* webpackChunkName: "safer_gambling_portal" */ '../../app/safer_gambling_portal/index.jsx'),
    dependencies: [
      CSSBundles.saferGamblingPortal,
    ],
    softDependencies: [
      TranslationsBundles.saferGamblingPortal,
      TranslationsBundles.occupationAndSalary,
    ],
    features: {
      contactUs: {
        app: contactUs,
        subModule: 'WithoutModal',
        dependencies: [
          CSSBundles.contactUs,
        ],
      },
      timeOut: {
        app: () => import(/* webpackChunkName: "time_out" */ '../../app/time_out/index.jsx'),
        dependencies: [
          CSSBundles.timeOut,
          CSSBundles.accountForms,
        ],
        softDependencies: [
          TranslationsBundles.timeOut,
        ],
      },
      occupationAndSalary,
      selfExclusion: selfExclude,
      sgQuestionnaire: {
        ...genericQuestionnaire,
        softDependencies: [
          TranslationsBundles.saferGamblingQuestionnaire,
        ],
      },
      allowanceQuestionnaire: {
        ...genericQuestionnaire,
        softDependencies: [
          TranslationsBundles.allowanceQuestionnaire,
        ],
      },
      stakeLimits: {
        ...stakeLimitsCommon,
        softDependencies: [
          TranslationsBundles.stakeLimits,
        ],
      },
      lossLimits: {
        ...stakeLimitsCommon,
        softDependencies: [
          TranslationsBundles.stakeLimits,
          TranslationsBundles.lossLimits,
        ],
      },
      depositLimits: {
        app: () => import(/* webpackChunkName: "deposit_limits" */ '../../app/deposit_limits/index.jsx'),
        dependencies: [
          CSSBundles.accountForms,
        ],
      },
    },
  },
  twoFactorAuth: {
    app: () => import(/* webpackChunkName: "two_factor_auth" */ '../../app/two_factor_auth/index.js'),
    dependencies: [
      CSSBundles.twofa,
      CSSBundles.sendVerifyCode,
      CSSBundles.accountForms,
      CSSBundles.intTelInput,
    ],
    softDependencies: [
      TranslationsBundles.security,
    ],
  },
  login: {
    app: () => import(/* webpackChunkName: "login" */ '../../app/login/index.jsx'),
    dependencies: [
      CSSBundles.login,
      CSSBundles.accountForms,
      CSSBundles.signInAndUpTabs,
    ],
  },
  search: {
    app: () => import(/* webpackChunkName: "search" */ '../../app/search/index.js'),
    dependencies: [
      CSSBundles.search,
    ],
    softDependencies: [
      TranslationsBundles.search,
    ],
    features: {
      casino: {
        app: () => import(/* webpackChunkName: "casino_search" */ '../../app/search/casino/index.jsx'),
        dependencies: [
          initCasino,
          CSSBundles.casinoV2,
        ],
      },
    },
  },
  asiaCashier: {
    app: () => import(/* webpackChunkName: "asia_cashier" */ '../../app/asia_cashier/index.jsx'),
    dependencies: [
      CSSBundles.asiaCashier,
      CSSBundles.accountForms,
      CSSBundles.liveChat,
    ],
    softDependencies: [
      TranslationsBundles.quickDeposit,
    ],
  },
  emailVerification: {
    app: () => import(/* webpackChunkName: "email_verification" */ '../../app/email_verification/index.js'),
  },
  casino: {
    app: () => import(/* webpackChunkName: "casino" */ '../../app/casino/v2/index.js'),
    dependencies: [
      initCasino,
      CSSBundles.casinoV2,
      CSSBundles.banners,
      CSSBundles.panicButton,
    ],
    softDependencies: [
      TranslationsBundles.panicButton,
    ],
  },
  virtuals: {
    app: () => import(/* webpackChunkName: "virtuals" */ '../../app/virtual_sports/index.js'),
    dependencies: [
      CSSBundles.virtualSports,
      initSportsbook,
    ],
  },
  sportsHome: {
    app: () => import(/* webpackChunkName: "home" */ '../../app/home/index.js'),
    dependencies: [
      CSSBundles.banners,
    ],
  },
  sportHome: {
    app: () => import(/* webpackChunkName: "sport_home" */ '../../app/sportsbook_components/sport_home/index.js'),
    dependencies: [
      initSportsbook,
      CSSBundles.mediaStreaming,
    ],
  },
  competitions: {
    ...sportSections,
    subModule: 'Competitions',
  },
  selfExclude,
  lottery: {
    app: () => import(/* webpackChunkName: "lottery" */ '../../app/casino/v2/features/lottoland/index.js'),
    dependencies: [
      CSSBundles.banners,
    ],
  },
  betCalculator: {
    app: () => import(/* webpackChunkName: "bet_calculator" */ '../../app/bet_calculator/index.js'),
    dependencies: [
      CSSBundles.betCalculator,
    ],
  },
  miniGames: {
    app: () => import(/* webpackChunkName: "mini_games" */ '../../app/casino/mini_games/index.js'),
    dependencies: [
      initCasino,
      CSSBundles.casinoV2,
    ],
  },
  miniLaunch: {
    app: () => import(/* webpackChunkName: "mini_launch" */ '../../app/casino/mini_launch/index.js'),
    dependencies: [
      initCasino,
      CSSBundles.casinoV2,
    ],
  },
  casinoGameLaunch: {
    app: () => import(/* webpackChunkName: "game_launch" */ '../../app/casino/v2/components/game_launch/index.js'),
    softDependencies: [
      TranslationsBundles.casinoRegulations,
    ],
  },
  casinoFtpGameLaunch: {
    app: () => import(/* webpackChunkName: "ftp_game_launch" */ '../../app/casino/v2/components/ftp_game_launch/index.jsx'),
    softDependencies: [
      TranslationsBundles.ftpGames,
    ],
  },
  casinoOverlay: {
    app: () => import(/* webpackChunkName: "casino_overlay" */ '../../app/casino/v2/features/overlay/index.js'),
  },
  offers: {
    app: () => import(/* webpackChunkName: "offers" */ '../../app/offers/index.jsx'),
    dependencies: [
      initSportsbook,
      CSSBundles.errorPage,
      CSSBundles.offers,
      CSSBundles.accountForms,
      CSSBundles.banners,
    ],
  },
  quickDeposit: {
    app: () => import(/* webpackChunkName: "quick_deposit" */ '../../app/quick_deposit/index.js'),
    dependencies: [
      CSSBundles.quickDeposit,
      // TODO: Create a cashier/global JS and css bundles for pending withdrawals
      // And to be extended by shared things (cashier form, etc)
      CSSBundles.cashierActions,
    ],
    softDependencies: [
      TranslationsBundles.quickDeposit,
      TranslationsBundles.payments,
    ],
  },
  staticPages: {
    app: () => import(/* webpackChunkName: "static_pages" */ '../../app/static_pages/index.js'),
  },
  errorPage: {
    app: () => import(/* webpackChunkName: "error_page" */ '../../app/error_page/index.js'),
    dependencies: [
      CSSBundles.errorPage,
    ],
  },
  mediaStreaming: {
    app: () => import(/* webpackChunkName: "media_streaming" */ '../../app/media_streaming/index.js'),
    dependencies: [
      CSSBundles.mediaStreaming,
    ],
  },
  betBuilderFixtures: {
    app: () => import(/* webpackChunkName: "bet_builder_fixtures" */ '../../app/bet_builder_fixtures/index.jsx'),
    dependencies: [
      CSSBundles.betBuilderFixtures,
    ],
  },
  betBuilder: {
    app: () => import(/* webpackChunkName: "bet_builder" */ '../../app/bet_builder/index.js'),
    dependencies: [
      CSSBundles.betBuilder,
      initSportsbook,
    ],
  },
  greyHounds: {
    app: () => import(/* webpackChunkName: "greyhounds" */ '../../app/greyhounds/index.js'),
    dependencies: [
      initSportsbook,
      CSSBundles.greyhounds,
    ],
  },
  greyHoundsTimeTable: {
    app: () => import(/* webpackChunkName: "greyhounds_time_table" */ '../../app/greyhounds_time_table/index.js'),
  },
  sportSectionsCustomList: {
    ...sportSections,
    subModule: 'CustomList',
  },
  customerConsentModal: {
    app: () => import(/* webpackChunkName: "consent_modal" */ '../../app/consent_modal/index.js'),
    dependencies: [],
  },
  fundsProtectionModal: {
    app: () => import(/* webpackChunkName: "funds_protection_modal" */ '../../app/funds_protection_modal/index.js'),
    dependencies: [],
  },
  inPlayOverview: {
    app: inPlayOverview,
    dependencies: [
      initSportsbook,
      CSSBundles.inPlayOverview,
    ],
    subModule: 'InPlayOverviewComponent',
  },
  inPlayWidget: {
    app: inPlayOverview,
    dependencies: [
      initSportsbook,
      CSSBundles.inPlayOverview,
    ],
    subModule: 'InPlayWidget',
  },
  betFinder: {
    app: () => import(/* webpackChunkName: "bet_finder" */ '../../app/bet_finder/index.js'),
    dependencies: [
      initSportsbook,
      CSSBundles.betFinder,
    ],
  },
  eventComponents: {
    app: () => import(/* webpackChunkName: "event_components" */ '../../app/sportsbook_components/event_components/index.js'),
    dependencies: [
      initSportsbook,
      CSSBundles.eventComponents,
    ],
  },
  eventLevel: {
    app: () => import(/* webpackChunkName: "event_level" */ '../../app/event_level/index.js'),
    dependencies: [
      CSSBundles.priceItUp,
      initSportsbook,
      scoreboard,
      CSSBundles.eventLevel,
      CSSBundles.scoreboard,
      CSSBundles.betBoost,
    ],
    softDependencies: [
      TranslationsBundles.eventLevel,
    ],
  },
  scoreboard: {
    app: scoreboard,
  },
  horseRacing: {
    app: horseRacing,
    dependencies: [
      initSportsbook,
      VCSTATE.EVENT_COMPONENTS_BUNDLE_PATH,
      CSSBundles.priceItUp,
      CSSBundles.racecard,
      CSSBundles.eventComponents,
      CSSBundles.eventLevel, // TODO: convert event level to generic sportsbook styles
      CSSBundles.scoreboard,
      CSSBundles.mediaStreaming,
    ],
    raceHeader: {
      app: horseRacing,
      subModule: 'RaceHeader',
    },
  },
  timeOutBanner: {
    app: () => import(/* webpackChunkName: "time_out_banner" */ '../../app/user_messages/components/time_out_banner/time_out_banner_container.jsx'),
    dependencies: [
      CSSBundles.timeOutBanner,
    ],
  },
  saferGamblingLanding: {
    app: () => import(/* webpackChunkName: "safer_gambling_landing" */ '../../app/safer_gambling_landing/index.js'),
    dependencies: [
      CSSBundles.saferGamblingLanding,
    ],
    softDependencies: [
      TranslationsBundles.saferGamblingQuestionnaire,
    ],
  },
  layoutApps: {
    leftComponents: {
      app: layoutApps,
      subModule: 'LeftComponents',
    },
    smartAppBanner: {
      app: layoutApps,
      subModule: 'SmartAppBanner',
      dependencies: [
        CSSBundles.appSmartBanner,
      ],
    },
    floatingNavigation: {
      app: layoutApps,
      subModule: 'FloatingNavigation',
      dependencies: [
        CSSBundles.floatingNavigation,
      ],
    },
    modalHelper: {
      app: layoutApps,
      subModule: 'ModalHelper',
    },
  },
  rightSidebar: {
    app: () => import(/* webpackChunkName: "right_sidebar" */ '../../app/layout_apps/right_sidebar/index.js'),
    softDependencies: [
      TranslationsBundles.betslipBetTypeTooltips,
    ],
  },
  betBoost: {
    app: () => import(/* webpackChunkName: "bet_boost" */ '../../app/sportsbook/home_components/bet_boost/index.js'),
    dependencies: [
      CSSBundles.betBoost,
      CSSBundles.eventLevel,
      initSportsbook,
    ],
  },
  luckyDip: {
    addToBetslip: luckyDipDependency('AddToBetslip'),
    betslip: luckyDipDependency('LuckyDipBetslip'),
    component: luckyDipDependency('LuckyDipComponent'),
    coupon: luckyDipDependency('LuckyDipCoupon'),
  },
  nextEvent: {
    app: () => import(/* webpackChunkName: "next_event" */ '../../app/sportsbook/next_event/index.jsx'),
    dependencies: [],
  },
  sportsbookFtpSportLaunch: {
    app: () => import(/* webpackChunkName: "ftp_sport_launch" */ '../../app/sportsbook_components/ftp_sport_launch/index.jsx'),
    dependencies: [
      CSSBundles.sportsbookFtpSportLaunch,
    ],
  },
  enhancedOutcomeOffers: {
    app: () => import(/* webpackChunkName: "enhanced_outcome_offers" */ '../../app/enhanced_outcome_offers/index.js'),
    dependencies: [],
  },
  acceptAllPriceChangesToggle: {
    app: acceptAllPriceChangesToggle,
    dependencies: [],
    softDependencies: [
      TranslationsBundles.acceptAllPriceChangesToggle,
    ],
  },
  acceptAllLineChangesToggle: {
    app: acceptAllLineChangesToggle,
    dependencies: [],
    softDependencies: [
      TranslationsBundles.acceptAllLineChangesToggle,
    ],
  },
  myTransactions: {
    app: () => import(/* webpackChunkName: "my_transactions" */ '../../app/my_transactions/index.jsx'),
    dependencies: [
      CSSBundles.myTransactions,
    ],
    softDependencies: [
      TranslationsBundles.myTransactions,
    ],
  },
  zendeskLiveChat: {
    app: () => import(/* webpackChunkName: "zendesk" */ '../../app/zendesk/index.js'),
    dependencies: [
      JSBundles.zendeskWebSDKUrl,
      CSSBundles.zendeskLiveChat,
    ],
    softDependencies: [
      TranslationsBundles.zendesk,
    ],
  },
  sportsbookBetslip: {
    app: () => import(/* webpackChunkName: "betslip" */ '../../app/sportsbook/betslip/index.js'),
    dependencies: [
      initSportsbook,
      acceptAllPriceChangesToggle,
      acceptAllLineChangesToggle,
      CSSBundles.betslipV2,
      CSSBundles.panicButton,
    ],
    softDependencies: [
      TranslationsBundles.betslip,
      TranslationsBundles.acceptAllPriceChangesToggle,
      TranslationsBundles.acceptAllLineChangesToggle,
      TranslationsBundles.panicButton,
    ],
  },
  sportsbookCashout: {
    app: () => import(/* webpackChunkName: "cashout" */ '../../app/cashout/index.js'),
    dependencies: [
      initSportsbook,
      CSSBundles.cashoutV2,
      CSSBundles.inPlayOverview,
    ],
  },
  selfExclusionSuccessModal: {
    app: () => import(/* webpackChunkName: "self_exclusion_success" */ '../../app/self_exclusion_success_modal/index.jsx'),
    dependencies: [
      CSSBundles.selfExclude,
    ],
    softDependencies: [
      TranslationsBundles.selfExclusion,
    ],
  },
  stakeLimitsBreachModal: {
    app: () => import(/* webpackChunkName: "stake_limits_breach" */ '../../app/stake_limits_breach_modal/index.jsx'),
    dependencies: [
      CSSBundles.stakeLimits,
    ],
    softDependencies: [
      TranslationsBundles.stakeLimits,
    ],
  },
  lossLimitsBreachModal: {
    app: () => import(/* webpackChunkName: "loss_limits_breach" */ '../../app/loss_limits_breach_modal/index.jsx'),
    dependencies: [
      CSSBundles.stakeLimits,
    ],
    softDependencies: [
      TranslationsBundles.lossLimits,
    ],
  },
  panicButton: {
    app: () => import(/* webpackChunkName: "panic_button" */ '../../app/panic_button/index.jsx'),
    dependencies: [
      CSSBundles.panicButton,
    ],
    softDependencies: [
      TranslationsBundles.panicButton,
    ],
  },
  occupationAndSalary,
  crackTheCodeZone: {
    app: () => import(/* webpackChunkName: "crack_the_code_zone" */ '../../app/crack_the_code/ctc_zone/index.js'),
    dependencies: [
      CSSBundles.crackTheCodeZone,
      CSSBundles.banners,
      ...BVVars?.siteConfigs?.global?.hasSportsbook ? [
        initSportsbook,
      ] : [],
    ],
  },
  crackTheCodeProgress: {
    app: () => import(/* webpackChunkName: "crack_the_code_progress" */ '../../app/crack_the_code/ctc_progress/index.jsx'),
    dependencies: [
      CSSBundles.crackTheCodeProgress,
    ],
  },
  kycRefresh: {
    app: () => import(/* webpackChunkName: "kyc_refresh" */ '../../app/kyc_refresh/index.js'),
    dependencies: [
      CSSBundles.personalDetails,
      CSSBundles.accountForms,
      CSSBundles.intTelInput,
    ],
    softDependencies: [
      TranslationsBundles.saferGamblingPortal,
    ],
  },
  KycTaxId: {
    app: () => import(/* webpackChunkName: "kyc_tax_id" */ '../../app/kyc_tax_id/index.js'),
    dependencies: [
      CSSBundles.liveChat,
      CSSBundles.verificationZone,
    ],
  },
};
