// TODO: As spoken with Eze, this should be a component not related to coupons
// General expandable card not composed of ul and li but of divs
// Please, update me
import List from 'bv-components/list';

const ExpandableList = (props) => (
  <List expandable {...props} />
);

export default ExpandableList;
