import { loggedIn } from 'bv-helpers/session';
import { t } from '../../i18n';
import routingSettings from '../config/routing_config';
import init from './init';
import show from './show';
import { getAccountData, getAccountRestriction } from './account';
import errorMessage from '../modals/error';
import { loggedInConfigurations } from '../config/base_config';
import userSettings from '../config/user_config';
import { loggedInRouting, loggedOutRouting } from './routing';

const showLivechat = ({ keepHidden = false } = {}) => {
  if (loggedIn()) {
    // Check if user is allowed to chat
    getAccountRestriction()
      .then(({ success, allowed }) => {
        if (success && allowed) {
          getAccountData().then((data) => {
            // apply user settings;
            loggedInConfigurations(data);
            userSettings(data);
            // apply routing
            loggedInRouting(data.customer_service_level);
          })
            .then(() => {
              init();
              if (!keepHidden) { show(); }
            });
        } else if (success && !allowed) {
          errorMessage(t('javascript.zendesk.error.restricted_use'));
        } else {
          errorMessage(t('something_went_wrong_try_again'));
        }
      });
  } else {
    loggedOutRouting();
    init();
    if (!keepHidden) { show(); }
  }
};

export default (service, options = {}) => {
  window.zE('webWidget', 'updateSettings', routingSettings(service));
  if (service === 'chat') {
    showLivechat(options);
  } else {
    init();
    show();
  }

  window.dispatchEvent(new Event('zE_hide_preloader'));
};
