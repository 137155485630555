import {
  memo, useRef, useContext, useState, useEffect,
} from 'react';
import PropTypes from 'prop-types';
import { useHistory, useLocation } from 'react-router-dom';
import classNames from 'classnames';
import { homeProduct } from 'global-config';
import { v as bvVar, isDesktop } from 'bv';
import { t } from 'bv-i18n';

import Icon from 'bv-components/icon';
import getSearchTerm from 'CasinoV2/helpers/get_search_term';
import ScrollingContext from 'CasinoV2/components/scrolling_context';
import { scrollToContentTop } from 'CasinoV2/helpers/scrolling';

const SearchInput = memo(({ setSearchActive }) => {
  const history = useHistory();
  const { pathname, search } = useLocation();

  const { slug } = bvVar('casinoLobbies').find((lobby) => (
    pathname.startsWith(`/${lobby.slug}`)
  )) ?? (
    homeProduct.startsWith('casino::')
      ? ({ slug: homeProduct.replace('casino::', '') })
      : ({ slug: 'casino' })
  );

  const inputRef = useRef(null);

  const searchTerm = getSearchTerm(search) || '';

  const [previousLocation, setPreviousLocation] = useState(`/${slug}`);
  const [searching, setSearching] = useState(pathname.startsWith(`/${slug}/search`));

  const gotoPreviousLocation = () => {
    history.push(previousLocation);
  };

  const clearSearch = () => {
    setSearching(false);
    gotoPreviousLocation();
  };

  const onChange = (event) => {
    const { value } = event.target;

    if (!value) {
      gotoPreviousLocation();
    } else {
      history.push(`/${slug}/search?term=${encodeURIComponent(value)}`);
    }
  };

  const wrapperClass = classNames('casino-search', { active: searching });

  const scrollingValues = useContext(ScrollingContext);

  const onFocus = () => {
    setSearching(true);

    if (!searching) setPreviousLocation(pathname);
    if (!isDesktop && !bvVar('top_nav_search')) scrollToContentTop(scrollingValues);
  };

  const onSubmit = (event) => {
    event.preventDefault();
    inputRef.current.blur();
  };

  useEffect(() => {
    if (!searchTerm && document.activeElement !== inputRef.current) {
      setSearching(false);
    } else {
      inputRef.current.focus();
    }
  }, [searchTerm]);

  useEffect(() => {
    if (setSearchActive) {
      setSearchActive(searching);
    }
  }, [searching]);

  return (
    <form className={wrapperClass} onSubmit={onSubmit}>
      <input
        className="casino-search__input"
        type="text"
        onChange={onChange}
        placeholder={t('javascript.casino.search')}
        onFocus={onFocus}
        onBlur={!searchTerm && (() => { setSearching(false); })}
        value={searchTerm}
        ref={inputRef}
      />
      <Icon
        id="close"
        className="casino-search__close"
        onClick={clearSearch}
        big
      />
    </form>
  );
});

SearchInput.propTypes = {
  setSearchActive: PropTypes.function,
};

SearchInput.defaultProps = {
  setSearchActive: null,
};

export default SearchInput;
