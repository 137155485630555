import { useState, useEffect } from 'react';

export default () => {
  const [isDocumentReady, setIsDocumentReady] = useState(false);

  const handleReadyEvent = () => setIsDocumentReady(true);

  useEffect(() => {
    if (document.readyState === 'complete') {
      handleReadyEvent();
    } else {
      window.addEventListener('load', handleReadyEvent);
    }

    return () => {
      window.removeEventListener('load', handleReadyEvent);
    };
  }, []);

  return isDocumentReady;
};
